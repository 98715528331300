@import '../materialize';

// Cards section
#testimonial-cards {
  // Padding and margin
  margin: 4rem 3rem;

  // Flex options
  justify-content: flex-start;
  align-items: center;

  &.dark-mode > .testimonial-card {
    background-color: var(--color-accent);
    h5,
    h6,
    p {
      color: var(--color-contrast);
    }
  }
}

.testimonial-card {
  // Padding and margin
  padding: 1rem;
  margin: 1rem 0;

  // Card sizing
  width: 50rem;
  min-height: 20rem;
  @media #{$medium-and-down} {
    width: 80vw;
  }
  @media #{$small-and-down} {
    width: 90vw;
  }

  // Card styling
  background-color: var(--color-contrast);
  border-radius: 1rem;

  h5,
  h6,
  p {
    color: var(--color-accent);
    text-align: left;
  }

  // Subsection styles
  .tcard-header {
    img {
      // Sizing & styling
      object-fit: cover;
      border-radius: 50%;
      height: 5rem;
      width: 5rem;
    }
    div {
      padding-left: 1rem;
      h5 {
        margin-bottom: 0.25rem;
      }
      h6 {
        font-size: 1.05rem;
      }
    }
    height: 5rem;
  }
  .tcard-body {
    // Padding and margin
    padding: 1.5rem 0 0;
  }
  .tcard-body,
  .tcard-footer {
    p {
      font-size: 1.4rem;
    }
  }
}
