@import '../materialize';

#about-picture {
  // Padding
  padding: 0 15rem;
  @media #{$medium-and-down} {
    padding: 0 10rem;
  }
  @media #{$small-and-down} {
    padding: 0 2rem;
  }

  display: flex;
  flex-direction: row;
  justify-content: center;

  img {
    border-radius: 1rem;

    margin: 0 0.5rem;
    width: 16rem;
    height: 16rem;
    @media #{$medium-and-down} {
      width: 12rem;
      height: 12rem;
    }
    @media #{$small-and-down} {
      width: 8rem;
      height: 8rem;
      margin-bottom: 2rem;
    }
  }
}

.image-section {
  flex: 1;

  &.dark-mode {
    img {
      filter: brightness(0.8) contrast(1.2);
    }
  }

  // Responsive styling
  padding: 0 3rem;
  @media #{$medium-and-down} {
    padding: 0 2rem;
  }
  @media #{$small-and-down} {
    padding: 0 1rem;
  }
}
.image-section-title {
  min-height: 5rem;
  @media #{$small-and-down} {
    min-height: 4rem;
  }
}
.image-section-description {
  // Set default font weight lower
  font-weight: 300;

  // Text styling
  div {
    font-size: 2rem;
    @media #{$medium-and-down} {
      font-size: 1rem;
    }
    @media #{$small-and-down} {
      font-size: 0.86rem;
    }
  }
  color: var(--color-contrast);

  // Collapsible
  .collapsible,
  .collapsible-header,
  .collapsible-body {
    background-color: var(--color-primary);
    border-color: var(--color-contrast);
    border-width: 0.1rem;
    // border: none;
  }

  // For main text
  bold {
    font-weight: bold;
  }
  emphasize {
    font-weight: 500;
  }

  a {
    color: var(--color-contrast-light);
    text-decoration: underline;
  }

  // Sections
  span {
    display: block;
    margin: 1.2rem 0;
  }
}
.image-section-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  img {
    border-radius: 1rem;

    width: 13rem;
    height: 13rem;
    @media #{$medium-and-down} {
      width: 7rem;
      height: 7rem;
    }
    @media #{$small-and-down} {
      width: 5rem;
      height: 5rem;
    }

    margin: 0.5rem 0.5rem;
  }
}
