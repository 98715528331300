.main-div {
  .page-title {
    animation: fadeInUp 0.8s both;
    -webkit-animation: fadeInUp 0.8s both;
  }
  padding: 2rem 0;
}

.fade-in-after-page-title-load {
  animation: fadeIn 0.8s both;
  -webkit-animation: fadeIn 0.8s both;
  animation-delay: 0.8s;
}
