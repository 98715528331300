footer {
  padding: 2rem 20%;
  // Link coloring
  a {
    color: var(--color-primary-dark);
  }

  // Spacing for footer menu
  #footer-left,
  #footer-right {
    flex: 1;
  }

  background-color: var(--color-accent);
}
.footer-selected {
  text-decoration: underline;
}
