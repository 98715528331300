@import '../materialize';
@import './variables.scss';

form > div {
  // Padding and margin
  padding: 1rem;
  margin: 1rem 0;

  // Card sizing
  width: 50rem;
  min-height: 20rem;
  @media #{$medium-and-down} {
    width: 80vw;
  }
  @media #{$small-and-down} {
    width: 90vw;
  }

  // Card styling
  background-color: var(--color-contrast);
  border-radius: 1rem;

  // Color for the star
  sup {
    color: var(--color-primary-dark);
  }

  // Dark mode styling
  &.dark-mode {
    background-color: var(--color-accent);
    sup {
      color: var(--color-contrast);
    }
  }

  // Input field
  .input-field {
    margin: 1rem;
  }

  // Submit button
  button {
    // Sizing and styling
    width: 20rem;
    height: 3rem;
    border-radius: 0.5rem;
    border-width: 0;

    // Coloring
    background-color: var(--color-primary);

    // Inner text
    color: var(--color-contrast);
    font-size: 1.5rem;
    font-weight: 500;

    &:focus {
      background-color: var(--color-primary);
    }
  }

  h4,
  h6 {
    color: var(--color-accent);
  }
  &.dark-mode {
    h4,
    h6 {
      color: var(--color-contrast);
    }
    color: var(--color-contrast);
  }
}

#contact-section {
  // Wrap on smaller screens
  flex-wrap: wrap;
  justify-content: center;

  #contact-text {
    margin: 1rem 0 0;
    h5 {
      margin: 0 0 2rem 2rem;
      a {
        color: var(--color-contrst);
        text-decoration: underline;
        margin: 0 0 0 1rem;
      }
    }
    @media #{$medium-and-down} {
      h5 {
        margin: 0 0 2rem 0;
      }
    }
    @media #{$extra-small-and-down} {
      h5 {
        font-size: 0.86rem !important;
      }
    }
  }
}
