#sidenavWrapper {
  // Sidenav takes up whole screen
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  // We need the sidenav to be in front of everything
  z-index: 1000;

  // Background color and transition
  background-color: var(--color-darken-background);
  animation: fadeIn ease 0.35s;
  -webkit-animation: fadeIn ease 0.35s;

  // Main sidenav
  #sidenav {
    // Positioning and sizing
    position: absolute;
    width: 24rem;
    max-width: 90%;
    height: 100%;
    z-index: 1001;
    background-color: var(--color-contrast);
    &.dark-mode {
      background-color: var(--color-accent);
    }
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;

    // Item styles
    ul {
      display: flex;
      flex-direction: column;

      li {
        display: flex;
        flex-direction: row;

        a {
          flex: 1;
          display: flex;
          align-items: center;

          // Set size of sidenav item
          height: 4rem;
          font-size: 1.6rem;
          > svg {
            font-size: 1.6rem;
          }

          // Set padding
          padding: 0 2rem;

          color: black;
        }
      }
    }
    &.dark-mode {
      ul {
        li {
          a {
            color: var(--color-contrast);
          }
        }
      }
    }
  }
}

// Selected sidenav items
.sidenav-selected {
  background-color: var(--color-selected);
}
