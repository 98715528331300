// Fade in up
@keyframes fadeInUp {
  from {
    transform: translate(0, 2rem);
  }

  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUp {
  from {
    transform: translate(0, 2rem);
  }

  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}
// Fade in
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
// Slide in
@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}
@-webkit-keyframes slide-in {
  100% {
    -webkit-transform: translateX(0%);
  }
}
