@import '../materialize';

#portfolio {
  .main-div {
    padding: 5rem 0;

    @media screen and (max-height: 40rem) {
      height: 150vh;
    }
  }
}
#landing-page {
  // Landing page animation
  opacity: 0;
  animation: fadeInUp 1.2s both;
  -webkit-animation: fadeInUp 1.2s both;
}
#about-me-section {
  // Padding for the always learning section
  padding: 8rem 8rem;
  @media #{$medium-and-down} {
    padding: 8rem 4rem;
  }
  @media #{$small-and-down} {
    padding: 8rem 1.2rem;
    p.flow-text {
      font-size: 1.4rem;
    }
  }

  background-color: var(--color-contrast);
  &.dark-mode {
    background-color: var(--color-accent);
  }
}

// Hero icon
#hero {
  width: 50rem;
  max-width: 100vw;
  position: absolute;
  bottom: 0;
  &.dark-mode {
    filter: invert(0.8);
  }
}

// Buttons
.button {
  &:hover {
    background-color: var(--color-selected-light);
  }
}
