@import '../materialize';

// Work Experience or Project Card
.wkexp-project-card {
  // Padding and margin
  padding: 1rem;
  margin: 1rem 0;

  // Card sizing
  width: 50rem;
  min-height: 20rem;
  @media #{$medium-and-down} {
    width: 80vw;
  }
  @media #{$small-and-down} {
    width: 90vw;
  }

  // Card styling
  background-color: var(--color-contrast);
  border-radius: 1rem;

  h5,
  h6,
  p {
    color: var(--color-accent);
    text-align: left;
  }

  &.dark-mode {
    background-color: var(--color-accent);

    h5,
    h6,
    p,
    li {
      color: var(--color-contrast);
      text-align: left;
    }
  }

  // Subsection styles
  .wxpjcard-header {
    img {
      // Sizing & styling
      object-fit: cover;
      height: 5rem;
      width: 5rem;
    }
    div {
      padding-left: 1rem;
      h5 {
        margin-bottom: 0.25rem;
      }
      h6 {
        font-size: 1.05rem;
      }
    }
    height: 5rem;
  }
  .wxpjcard-body {
    ul {
      font-size: 1.5rem;
      @media #{$small-and-down} {
        font-size: 1.2rem;
      }

      text-align: left;
    }
  }
  .wxpjcard-footer {
    padding: 0.5rem 0 0;
  }
  .wxpjcard-body,
  .wxpjcard-footer {
    p {
      font-size: 1.4rem;
    }
  }
}
